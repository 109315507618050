
#all{
    /* display: grid;
    grid-template-areas: 
    "header"
    "main"
    "footer";
    grid-template-rows: 15% 1fr 15%;     */
}

#LessonHeader{
    background: orange;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    /* grid-area: header; */
}

#main{
   /* grid-area: main; */
    
}

#f { 
    background: burlywood;
    /* grid-area: footer; */
    text-align: center;    
}

.selectedExercise {
    border: 2px solid darkgreen;
    display: inline-block;
    border-radius: 50%;
    margin: 10px;
    padding: 10px;
    font-size: 12px;
    background-color: lightgreen;
}

.unselectedExercise {
    border: 1px solid #638e2a;
    display: inline-block;
    border-radius: 50%;
    margin: 10px;
    padding: 10px;
    font-size: 12px;
}

.lessonNumberDiv {
    margin-left: 6px;
    margin-right: 6px;
}