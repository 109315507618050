.joinTable{
    min-width: 40%;    
    margin: 0 auto;    
}
td,th{
    border: 2px solid darkgreen
}

.helpButtonDiv{    
    text-align: center;
}

.selectedTd{
    border: 3px solid lightseagreen;
}

.joinValuesMainDiv{
    /* padding-bottom: 15px; */
}

.joinValuesTextDiv{
    text-align: center;
    font-weight: bolder;
    padding: 10px;
}

.colNmbDiv{        
    display: inline-block;
    border-radius: 50%;
    margin: 10px;
    padding: 10px;
    font-size: 15px;    
}

.colNmbSpan{
    margin-left: 7px;
    margin-right: 7px;
    font-weight: bolder;
}

.colNmbDiv-1{
    border: 3px solid lightgray;
    
}

.colNmbDiv0{
    
    border: 2px solid rgb(255, 230, 0);
    background-color: yellow;
}

.colNmbDiv1{
    border: 2px solid darkgreen;
    background-color: lightgreen;
}

.colNmbDiv2{
    border: 2px solid darkblue;
    background-color: lightblue;
}

.colNmbDiv3{
    border: 2px solid rgb(224, 170, 104);
    background-color: rgb(253, 234, 199);
}

.colNmbDiv4{
    border: 2px solid grey;
    background-color: lightgray;
}

.colNmbDiv5{
    border: 2px solid rgb(228, 30, 30);
    background-color: rgba(252, 127, 127, 0.932);
}

.colNmbDiv6{
    border: 2px solid purple;
    background-color: rgb(252, 149, 252);
}

.colNmbDiv7{
    border: 2px solid rgb(255, 136, 0);
    background-color: rgb(245, 209, 142);
}

.colNmbDiv8{
    border: 2px solid aqua;
    background-color: rgb(200, 255, 255);
}

.colNmbDiv9{
    border: 2px solid rgb(111, 170, 21);
    background-color: greenyellow;
}