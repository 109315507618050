.oneStatementDiv {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid gray;
}

.oneTranslationDiv {
    font-style: italic;
    color: gray;
}

#foreignWord {
    display: none;
}

.divMainText {
    text-align: center;
}

#mainText {
    text-align: center;
    font-weight: bolder;
    padding: 10px;
}

.buttons {
    text-align: center;
    padding: 10px;
    margin: 10px;
}

.checkButton {
    padding-left: 10%;
    padding-right: 10%;
}

audio {
    width: 100%;
}