div, button{
    font-size: 20px;
  }

  .helpButtonDiv{    
    text-align: center;
}

  .brickSet{
      border: 2px solid black;     
      border-radius: 10px; /* Радиус скругления */
      margin: 20px;
      padding: 20px;    
    }
  
    .sentenceSet{
      border: 2px solid black;     
      border-radius: 10px; /* Радиус скругления */
      margin: 20px;
      padding: 20px;
    }
  
    .sentenceBrick{
      border: 2px solid gray;     
      border-radius: 5px; /* Радиус скругления */
      margin: 10px 5px 10px 5px;
      display: inline-block;
      padding: 5px;
    }
    .sentenceBrick-hidden{
      border: 2px solid gray;     
      border-radius: 5px; /* Радиус скругления */
      margin: 10px 5px 10px 5px;
      display: none;
      padding: 5px;
    }
    .fixSentenceBrick{
      border: 2px solid gray;     
      border-radius: 5px; /* Радиус скругления */
      margin: 10px 5px 10px 5px;
      display: inline-block;
      padding: 5px;
    }
  
    .brickWord{
      border: 2px solid gray;     
      border-radius: 5px; /* Радиус скругления */
      margin: 10px;
      display: inline-block;
      padding: 5px;
    }
  
    .brickWord-hidden{
      display: none;      
    }

    .div-hidden{
      display: none;
    }
  
    .ruSentence{
      margin: 5;
      padding: 5px;
      text-align: center;
      font-weight: bold;    
    }

    .checkButton{
      padding-left: 10%;
      padding-right: 10%;
    }