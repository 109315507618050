
#all{
    /* display: grid;
    grid-template-areas: 
    "header"
    "main"
    "footer";
    grid-template-rows: 15% 1fr 15%;     */
}

.exampleSpan{
    text-align: center;
    padding-bottom: 20px;
    padding-right: 10px;
    font-size: 30px;
    color: rgb(5, 41, 109);;
    font-weight: bold;
    font-style: italic;    
    text-decoration: underline;
}

.audioButton{
    padding-left: 10px;
}


.wordDiv{
    text-align: center;
    padding-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
    color: rgb(17, 61, 17);
}

.sampleDiv{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 30px;
    color: rgb(5, 41, 109);
    font-weight: bold;
}

.sampleTranslationDiv{
    text-align: center;
    padding-bottom: 20px;
    font-size: 30px;
    color: rgb(5, 41, 109);
    font-weight: bold;
}

.translation{
    text-align: center;
    padding: 10px;
    font-size: 30px;
    font-weight: bold;
}

.errorInfo{
    text-align: center;
    padding-bottom: 10px;
    font-size: 20px;
    color: red;    
}

.correctWordInfo{
    text-align: center;
    padding-bottom: 10px;
    font-size: 20px;
    color: red;    
}

.userInputWord{
    text-align: center;    
}

.img{    
    text-align: center;    
    margin-top: 30px;
}

.checkButton{
    padding-left: 10%;
    padding-right: 10%;
    margin-left: 5%;
    margin-right: 5%;
  }

.buttonsDiv{
    text-align: center;    
    padding-bottom: 20px;
}



#groupHeader{
    background: orange;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    /* grid-area: header; */
    text-align: center;    
    font-weight: bold;
    position: relative;
}

.cbxVocabularyMode{
    position: absolute;
    right: 0px;
}

#main{
   /* grid-area: main; */
    
}
#vocabularyFooter { 
    background: burlywood;
    /* grid-area: footer; */
    text-align: left;    
    padding: 15px;
}

.selectedExercise {
    border: 2px solid darkgreen;
    display: inline-block;
    border-radius: 50%;
    margin: 10px;
    padding: 10px;
    font-size: 12px;
    background-color: lightgreen;
}

.unselectedExercise {
    border: 1px solid #638e2a;
    display: inline-block;
    border-radius: 50%;
    margin: 10px;
    padding: 10px;
    font-size: 12px;
}

.lessonNumberDiv {
    margin-left: 6px;
    margin-right: 6px;
}

