.all {
    display: grid;
    grid-template-areas: "h h h" "img1 img2 img3" "img4 img5 img6";
    
    /* height: 100%;
    width: 100%; */
    grid-gap: 0px;
    margin: 0;
    /* grid-template-rows: 15% 35% 35% 15%;
    grid-template-columns: 33% 33% 34%; */
    grid-template-rows: 1fr 3fr 3fr;
    grid-template-columns: 1fr 1fr ;    
}

.image6Lst {
    max-width: 100%;
     max-height: 100%;
    /* background-position: center center;     */
    padding: 10px;    
    
}
 
#freignWord{
    text-align: center;
    font-weight: bold;    
    text-transform: uppercase;    
}

.head {
   /* padding: 20px; */
    /* background: orange;  */
    grid-area: h;
    text-align: center;
}

#img0 {
    /* padding: 20px; */
    /* background: red; */
    background: rgb(240, 238, 238);
    grid-area: img1;
    text-align: center;
}

#img1 {
    /* padding: 20px; */
    /* background: blue; */
    background: lightyellow;
    grid-area: img2;
    text-align: center;
}

#img2 {
    /* padding: 20px; */
    /* background: lightgreen; */
    background: rgb(240, 238, 238);
    grid-area: img3;
    text-align: center;
}

#img3 {
    /* padding: 20px; */
    /* background: lightblue; */
    background: lightyellow;
    grid-area: img4;
    text-align: center;

}

#img4 {
    /* padding: 20px; */
    /* background: lightsalmon; */
    background: rgb(240, 238, 238);
    grid-area: img5;
    text-align: center;

}

#img5 {
    /* padding: 20px; */
    /* background: green; */
    background: lightyellow;
    grid-area: img6;
    text-align: center;

}

