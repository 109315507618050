.selectedUnit {
    border: 4px solid darkgreen;
    display: inline-block;
    border-radius: 50%;
    margin: 20px;
    padding: 20px;
    font-size: 25px;
    background-color: lightgreen;
}

.unselectedUnit {
    border: 2px solid #638e2a;
    display: inline-block;
    border-radius: 50%;
    margin: 20px;
    padding: 20px;
    font-size: 25px;
}

.units {}

.unitsHeader {
    display: inline-block;
    margin: 10px;
    padding: 10px;
    font-size: 25px;
    color: rgb(17, 61, 17);
    font-weight: 700;
}

.selectedLesson {
    border: 4px solid darkgreen;
    display: inline-block;
    border-radius: 40%;
    margin: 20px;
    padding: 20px;
    font-size: 25px;
    background-color: lightgreen;
}

.unselectedLesson {
    border: 2px solid #638e2a;
    display: inline-block;
    border-radius: 40%;
    margin: 20px;
    padding: 20px;
    font-size: 25px;
}

.lessons {}

.btnStartDiv {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 25px;
}

.ExercisesInfoDiv1 {
    padding-left: 20px;
    font-size: 25px;
    color: rgb(17, 61, 17);
    font-weight: 700;
}

.ExercisesInfoDiv2 {
    padding-left: 20px;
    font-size: 20px;
    color: rgb(17, 61, 17);
    font-weight: 700;
}

.unitNumberDiv {
    margin-left: 12px;
    margin-right: 12px;
}








.lessonsConainer {
    color: antiquewhite;
    width: 100%;
}

.lessonRow {
    float: left;
    width: 50%;
}