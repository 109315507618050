.vocabularyGroupDiv{
    margin: 10px;
    /* position: relative;  */
}

.vocabularyGroupNameSpan{
    margin: 15px;
    color: darkblue;
    font-weight: bolder;
    font-style: italic;
}

.vocabularyGroupMessage{
    margin: 15px;
    width: 100%;
 
}

.groupButton{
     /* width: 20%;
    position: absolute;
    right: 0px;  */
}